import React, { useRef, useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import CampaignInfo from './CampaignInfo';
import CampaignDailyAcps from './CampaignDailyAcps';
import CampaignChart from './CampaignChart';
import CampaignSwot from './CampaignSwot';
import CampaignAcpsCompara from './CampaignAcpsCompara';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, child, get, update, push } from "firebase/database";
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const API_URI_ACPS_CAMPAIGN = "https://api-vinbolai-dev.azurewebsites.net/acps_campaign";

const Campaign = ({
  currCnpj,
  userTier,
  clientGoodCommentsData,
  clientBadCommentsData,
  handleModalFilterCampanha,
  setFilterPostUrl,
  isGroup,
  onCampaign,
  activeTutorial,
  darkMode
}) => {
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaignIndex, setSelectedCampaignIndex] = useState(null);
  const [campaignPeriod, setCampaignPeriod] = useState({});
  const [campaignPosts, setCampaignPosts] = useState([]);
  const [comprehensiveDataDailyCampaign, setComprehensiveDataDailyCampaign] = useState([]);
  const [comprehensiveDataWeeklyCampaign, setComprehensiveDataWeeklyCampaign] = useState([]);
  const [acpsClientData, setAcpsClientData] = useState([]);
  const componentRef = useRef(null);

  const getFirstPartIfCommaSeparated = (str) => {
    const regex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
    const parts = str.split(',');
    const firstCnpj = parts.find(part => regex.test(part.trim()));
    return firstCnpj ? firstCnpj.trim() : null;
  };

  const handleExportToPdf = () => {
    return new Promise((resolve, reject) => {
      const input = componentRef.current;
      html2canvas(input, {
        scale: 2,
        scrollX: 0,
        scrollY: 0,
        windowWidth: document.documentElement.scrollWidth,
        windowHeight: document.documentElement.scrollHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('campaign_report.pdf');
        resolve();
      })
      .catch((error) => reject(error));
    });
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const dbRef = ref(getDatabase());
        let cnpjClient = getFirstPartIfCommaSeparated(currCnpj);
        cnpjClient = cnpjClient.replace(/[./-]/g, ''); // Clean the string
        get(child(dbRef, `campaign-analysis/${cnpjClient}`)).then((snapshot) => {
          if (snapshot.exists()) {
            const campaignData = snapshot.val();
            const keys = Object.keys(campaignData);
            keys.sort((a, b) => parseInt(a) - parseInt(b));
            const campaignsArray = keys.map(key => ({ ...campaignData[key], id: key }));
            setCampaigns(campaignsArray);
            const lastCampaignIndex = campaignsArray.length - 1;
            setSelectedCampaignIndex(lastCampaignIndex);
            const lastCampaign = campaignsArray[lastCampaignIndex];
            setCampaignPeriod(lastCampaign.period);
            setCampaignPosts(lastCampaign.posts || []);
            setFilterPostUrl(lastCampaign.posts ? lastCampaign.posts.map(post => post.url) : []);
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
      }
    });
  }, [currCnpj, setFilterPostUrl]);

  useEffect(() => {
    if (currCnpj && campaignPosts.length > 0) {
      let cnpjClient = getFirstPartIfCommaSeparated(currCnpj);
      console.log(`${API_URI_ACPS_CAMPAIGN}?cnpj=${cnpjClient}&post_url=${campaignPosts.map(obj => obj.url).join(',')}&version=2.0.0`)
      axios
        .get(`${API_URI_ACPS_CAMPAIGN}?cnpj=${cnpjClient}&post_url=${campaignPosts.map(obj => obj.url).join(',')}&version=2.0.0`)
        .then(res => {
          setAcpsClientData(res.data);
          console.log(res.data);
        });
    }
  }, [currCnpj, campaignPosts]);

  const handleCampaignUpdate = (newName, newPeriod, editedPosts) => {
    const updatedCampaigns = [...campaigns];
    updatedCampaigns[selectedCampaignIndex] = {
      ...updatedCampaigns[selectedCampaignIndex],
      name: newName,
      period: newPeriod,
      posts: editedPosts
    };
    setCampaigns(updatedCampaigns);
    setCampaignPeriod(newPeriod);
    setCampaignPosts(editedPosts);

    const db = getDatabase();
    let cleanedCnpj = currCnpj.replace(/[./-]/g, ''); // Clean the CNPJ
    const campaignRef = `campaign-analysis/${cleanedCnpj}`;

    const updates = {};
    updates[`${campaignRef}/${updatedCampaigns[selectedCampaignIndex].id}/name`] = newName;
    updates[`${campaignRef}/${updatedCampaigns[selectedCampaignIndex].id}/period/startDate`] = newPeriod.startDate;
    updates[`${campaignRef}/${updatedCampaigns[selectedCampaignIndex].id}/period/endDate`] = newPeriod.endDate;
    updates[`${campaignRef}/${updatedCampaigns[selectedCampaignIndex].id}/posts`] = editedPosts;

    update(ref(db), updates)
      .then(() => {
        console.log('Update succeeded.');
      })
      .catch((error) => {
        console.error('Update failed: ' + error.message);
      });
  };

  const handleCampaignChange = (newCampaignName) => {
    const newIndex = campaigns.findIndex(campaign => campaign.name === newCampaignName);
    if (newIndex !== -1) {
      setSelectedCampaignIndex(newIndex);
      const newCampaign = campaigns[newIndex];
      setCampaignPeriod(newCampaign.period);
      setCampaignPosts(newCampaign.posts || []);
      setFilterPostUrl(newCampaign.posts ? newCampaign.posts.map(post => post.url) : []);
    }
  };

  const handleCampaignAdd = (newCampaign) => {
    const db = getDatabase();
    let cleanedCnpj = currCnpj.replace(/[./-]/g, ''); // Clean the CNPJ
    const campaignRef = `campaign-analysis/${cleanedCnpj}`;

    const newCampaignRef = push(child(ref(db), campaignRef));
    const newCampaignId = newCampaignRef.key;

    // Update the main campaign details
    const campaignUpdates = {};
    campaignUpdates[`${campaignRef}/${newCampaignId}`] = newCampaign;

    update(ref(db), campaignUpdates)
      .then(() => {
        // Update the SWOT frequency separately
        const swotFrequencyUpdates = {};
        swotFrequencyUpdates[`${campaignRef}/${newCampaignId}/swot-analysis/frequency`] = newCampaign.swotFrequency;

        update(ref(db), swotFrequencyUpdates)
          .then(() => {
            setCampaigns(prevCampaigns => {
              const updatedCampaigns = [...prevCampaigns, { ...newCampaign, id: newCampaignId }];
              const lastCampaignIndex = updatedCampaigns.length - 1;
              setSelectedCampaignIndex(lastCampaignIndex); // Set the new campaign as the current campaign
              setCampaignPeriod(newCampaign.period);
              setCampaignPosts([]);
              setFilterPostUrl([]);
              return updatedCampaigns;
            });
            console.log('New campaign added.');
          })
          .catch((error) => {
            console.error('Update SWOT frequency failed: ' + error.message);
          });
      })
      .catch((error) => {
        console.error('Add campaign failed: ' + error.message);
      });
  };

  const calculateActivePeriod = (targetMonth, targetYear) => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();
    return (targetYear - currentYear) * 12 + (targetMonth - currentMonth);
  };

  const createCampaignComprehensiveData = (acpsClientData) => {
    let now = new Date();
    let localOffset = now.getTimezoneOffset() / 60;
    let targetOffset = -3;
    let offsetDifference = localOffset - targetOffset;
    let currentDate = new Date(now.getTime() + offsetDifference * 60 * 60 * 1000);
    var lastDataAprox = acpsClientData[acpsClientData.length - 1]['date'];
    var data = {
      daily: [], // Data for daily chart
      monthly: [], // Data for monthly chart
      yearly: [], // Data for yearly chart
      weekly: [] // Data for ACPS Compara
    };

    for (let chart = 0; chart <= 3; chart++) {
      let dateBegin, dateEnd;
      switch (chart) {
        case 0: // Monthly chart setup
          dateBegin = lastDataAprox;
          dateEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString().split('T')[0];
          break;
        case 1: // Daily chart setup
          dateBegin = lastDataAprox;
          dateEnd = dateBegin;
          break;
        case 2: // Yearly chart setup
          dateBegin = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0];
          dateEnd = new Date().toISOString().split('T')[0];
          break;
        case 3: // Weekly chart setup
          const weeklyAverage = {};
          let lastKnownACPS = 0; // Adjust based on your needs

          // Initialize weeklyAverage for each week in the range
          acpsClientData.forEach(item => {
            const itemDate = new Date(item.date);
            const [year, week] = getWeekNumber(itemDate);
            const weekKey = `${year}-W${week}`;

            if (!weeklyAverage[weekKey]) {
              weeklyAverage[weekKey] = { sum: 0, count: 0 };
            }

            weeklyAverage[weekKey].sum += item.acps;
            weeklyAverage[weekKey].count++;
            lastKnownACPS = item.acps; // Keep track of the last known ACPS
          });

          // Calculate averages for weeks
          Object.keys(weeklyAverage).forEach(weekKey => {
            if (weeklyAverage[weekKey].count > 0) {
              const average = weeklyAverage[weekKey].sum / weeklyAverage[weekKey].count;
              data.weekly.push({ date: `${weekKey}-01`, acpsValue: parseFloat(average.toFixed(3)) });
            } else {
              // For weeks without data, use the last known ACPS value
              data.weekly.push({ date: `${weekKey}-01`, acpsValue: lastKnownACPS });
            }
          });
          break;
      }

      // Populate data for the current chart type
      let whileLimit = chart === 2 ? 12 : 31; // Adjust based on chart type
      for (let dayStart = 1; dayStart <= whileLimit; dayStart++) {
        var cleanClientAcps = handleApiRes(chart, dayStart, acpsClientData, dateBegin, dateEnd);

        if (cleanClientAcps) {
          // Construct your data point based on your specific needs
          let dataPoint = {
            date: chart < 2 ? `${dateBegin.split('T')[0].split('-').slice(0, 2).join('-')}-${formatNumberWithLeadingZero(dayStart)}` : cleanClientAcps.date,
            acpsValue: chart < 2 ? cleanClientAcps.acpsValue : cleanClientAcps.acpsValue
          };

          // Add the constructed data point to the appropriate array
          switch (chart) {
            case 0: // Add to monthly data
              data.monthly.push(dataPoint);
              break;
            case 1: // Add to daily data
              data.daily.push(dataPoint);
              break;
            case 2: // Add to yearly data
              data.yearly.push(dataPoint);
              break;
          }
        }
      }
    }

    return data;
  };

  const handleApiRes = (chartOpt, index, data, date_range_begin, date_range_end) => {
    if (chartOpt < 2) { // Daily or monthly chart
      const updatedDate = new Date(`${date_range_begin}T00:00:00-03:00`);
      updatedDate.setDate(index);
      const dateString = updatedDate.toISOString().split('T')[0];

      const matchingObj = data.find(item => item.date === dateString);
      return matchingObj ? { date: dateString, acpsValue: parseFloat(matchingObj.acps.toFixed(3)) } : null;
    } else { // Yearly chart
      const startDate = new Date(date_range_begin);
      const endDate = new Date(date_range_end);
      const monthlyAverage = {};
      let lastKnownACPS = 0; // This will store the last known ACPS value

      // Initialize monthlyAverage for each month in the range to ensure all months are accounted for
      for (let d = new Date(startDate); d <= endDate;) {
        const monthKey = d.toISOString().slice(0, 7); // YYYY-MM
        monthlyAverage[monthKey] = { sum: 0, count: 0 };
        d.setMonth(d.getMonth() + 1);
        d.setDate(1); // Set the day to the 1st to avoid end-of-month issues
      }

      // Populate the monthlyAverage with actual data
      data.forEach(item => {
        const itemDate = new Date(item.date);
        if (itemDate >= startDate && itemDate <= endDate) {
          const monthKey = item.date.slice(0, 7); // YYYY-MM
          monthlyAverage[monthKey].sum += item.acps;
          monthlyAverage[monthKey].count++;
          lastKnownACPS = item.acps; // Update last known ACPS whenever we find actual data
        }
      });

      // Backfill missing ACPS values with the last known value
      Object.keys(monthlyAverage).forEach(key => {
        if (monthlyAverage[key].count === 0) {
          monthlyAverage[key].sum = lastKnownACPS;
          monthlyAverage[key].count = 1; // Assume one entry to avoid division by zero
        }
      });

      // Calculate averages and prepare result format
      const averages = Object.entries(monthlyAverage).map(([month, { sum, count }]) => {
        return { month, average: sum / count };
      });

      return averages.map(({ month, average }) => {
        return {
          date: `${month}-01`,
          acpsValue: parseFloat(average.toFixed(3))
        };
      });
    }
  };

  const formatNumberWithLeadingZero = (number) => {
    let numberString = number.toString();
    if (numberString.length === 1) {
      numberString = '0' + numberString;
    }
    return numberString;
  };

  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return [d.getUTCFullYear(), weekNo];
  };

  const generateDataForRange = () => {
    const daily = [];
    const weekly = [];
    const uniqueWeeks = new Set();  // Global set to track unique weeks
    const start = new Date(campaignPeriod.startDate);
    const end = new Date(campaignPeriod.endDate);
    const startMonth = start.getMonth();
    const endMonth = end.getMonth();
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();

    for (let year = startYear; year <= endYear; year++) {
      const monthStart = year === startYear ? startMonth : 0;
      const monthEnd = year === endYear ? endMonth : 11;

      for (let month = monthStart; month <= monthEnd; month++) {
        const activePeriod = calculateActivePeriod(month, year);
        const monthStartDate = (month === startMonth && year === startYear) ? start : new Date(year, month, 1);
        const monthEndDate = (month === endMonth && year === endYear) ? end : new Date(year, month + 1, 0);

        // Fetch data for the month
        const comprehensiveData = createCampaignComprehensiveData(acpsClientData);

        console.log(comprehensiveData);

        // Map to required format for daily data and apply filter
        const formattedDailyData = acpsClientData
          .filter(day => {
            const dayDate = new Date(day.date);
            return dayDate >= monthStartDate && dayDate <= monthEndDate;
          })
          .map(day => ({
            date: day.date,
            acpsValue: day.acps
          }));

        daily.push(...formattedDailyData);

        // Filter and map weekly data, ensuring no duplicates
        comprehensiveData.weekly.forEach(week => {
          const weekParts = week.date.split('-W');
          const weekYear = parseInt(weekParts[0]);
          const weekNumber = parseInt(weekParts[1]);

          const weekStartDate = new Date(weekYear, 0, 1 + (weekNumber - 1) * 7);
          const weekEndDate = new Date(weekYear, 0, 1 + (weekNumber - 1) * 7 + 6);

          if (
            (weekStartDate <= monthEndDate && weekStartDate >= monthStartDate) ||
            (weekEndDate >= monthStartDate && weekEndDate <= monthEndDate) ||
            (weekStartDate <= monthStartDate && weekEndDate >= monthEndDate)
          ) {
            if (!uniqueWeeks.has(week.date)) {
              uniqueWeeks.add(week.date);
              weekly.push({
                week: week.date,
                acps: week.acpsValue
              });
            }
          }
        });
      }
    }

    setComprehensiveDataDailyCampaign(acpsClientData);  // TODO: Trabalhar no filtro de período para plot diário. Não está funcionando.
    setComprehensiveDataWeeklyCampaign(weekly);
    console.log(weekly);
  };

  useEffect(() => {
    if (acpsClientData.length > 0 && Object.keys(campaignPeriod).length > 0) {
      generateDataForRange();
    }
  }, [campaignPeriod, acpsClientData]);

  const offset = -3; // UTC-3

  const filterAcpsDataByPeriod = (data, period) => {
    const start = new Date(period.startDate);
    const end = new Date(period.endDate);
    return data.filter(item => {
      const itemDate = new Date(item.date);
      return itemDate >= start && itemDate <= end;
    });
  };

  const filteredAcpsClientData = filterAcpsDataByPeriod(acpsClientData, campaignPeriod);

  const getCurrentDateOrEndDate = (period) => {
    const endDate = new Date(period.endDate);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return endDate < today ? period.endDate : yesterday.toISOString().split('T')[0];
  };
  
  const isCampaignFinished = (period) => {
    const endDate = new Date(period.endDate);
    const today = new Date();
    return endDate < today;
  };
  
  const currentEndDate = getCurrentDateOrEndDate(campaignPeriod);
  const campaignFinished = isCampaignFinished(campaignPeriod);

  return (
    <div>
      <div ref={componentRef} id="campaignComponent">  {/* This ID is used for PDF export */}
        <CampaignInfo
          campaigns={campaigns}
          campaignPeriod={campaignPeriod}
          campaignPosts={campaignPosts}
          onCampaignUpdate={handleCampaignUpdate}
          handleModalFilterCampanha={handleModalFilterCampanha}
          handleExportToPdf={handleExportToPdf}
          onCampaignChange={handleCampaignChange}
          onCampaignAdd={handleCampaignAdd}
        />
        <CampaignDailyAcps
          date={currentEndDate} // Show ACPS for the current date or end date of the campaign
          acpsClientData={filteredAcpsClientData}
        />
        <Grid container spacing={3}>

          {/* Daily ACPS */}
          <Grid item xs={12} md={6} lg={6} id='campaign-daily-chart'>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <CampaignChart
                chartOpt="daily"
                currCnpj={currCnpj}
                userTier={userTier}
                clientGoodCommentsData={clientGoodCommentsData}
                clientBadCommentsData={clientBadCommentsData}
                chartData={comprehensiveDataDailyCampaign}
                dateRange={campaignPeriod}
                campaignPosts={campaignPosts.map(item => item.url)} // Pass only the URLs as a new list
                onCampaign={onCampaign}
                darkMode={darkMode}
              />
            </Paper>
          </Grid>
          {/* Weekly ACPS */}
          <Grid item xs={12} md={6} lg={6} id='acps-weekly-chart'>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <CampaignChart
                chartOpt="weekly"
                chartData={comprehensiveDataWeeklyCampaign}
                dateRange={campaignPeriod}
                darkMode={darkMode}
              />
            </Paper>
          </Grid>

          {/* SWOT Analysis */}
          <Grid item xs={12} id='swot-analysis-campaign'>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CampaignSwot cnpjClient={currCnpj} isGroup={isGroup} campaignIndex={selectedCampaignIndex} />
            </Paper>
          </Grid>

          {/* ACPS Compara */}
          <Grid item xs={12} id='acps-compara-campaign'>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <CampaignAcpsCompara 
              data={comprehensiveDataWeeklyCampaign} 
              cnpjClient={currCnpj} 
              isGroup={isGroup} 
              userTier={userTier} 
              darkMode={darkMode}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Campaign;
