import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Setup from "./screens/Setup.jsx";
import Dashboard_ from "./screens/Dashboard.jsx";
import SignIn_ from "./screens/SignIn.jsx";
import SignUp_ from "./screens/SignUp.jsx";
import Calculator from "./screens/Calculator.jsx";
import RedirectToHome from './screens/RedirectToHome';
import PrivacyPolicy from "./components/Sections/PrivacyPolicy.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/setup" element={<Setup />} />
        <Route path="/signup" element={<SignUp_ />} />
        <Route path="/signin" element={<SignIn_ />} />
        <Route path="/dashboard" element={<Dashboard_ />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/teste-gratis-com-sua-marca" element={<RedirectToHome />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

