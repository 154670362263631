import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TweetBox from '../../Elements/TweetBox';
import axios from 'axios';
import Divider from '@mui/material/Divider';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';

export default function Mentions({ 
    dataPoint, 
    targetCnpj, 
    currentTags, 
    setCurrentTags, 
    filterWords,
    filterDateRange,
    filterPostUrl 
}) {
    const [commentsData, setCommentsData] = React.useState([]);
    const [currPage, setCurrPage] = React.useState(1);
    const [totalComments, setTotalComments] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [selectedComments, setSelectedComments] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [newClassification, setNewClassification] = React.useState('pos');
    const listInnerRef = React.useRef(null);

    const transformDate = (dateString) => {
        const options = { timeZone: 'UTC', month: 'short', day: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('pt-BR', options);
    };

    const matchTags = (dataSource, tags) => {
        const lowerCaseDataSource = dataSource.toLowerCase();
        return tags.length === 0 || tags.some(tag => lowerCaseDataSource.includes(tag.toLowerCase()));
    };

    const matchWords = (text, words) => {
        const lowerCaseText = text.toLowerCase();
        return words.length === 0 || words.every(word => lowerCaseText.includes(word.toLowerCase()));
    };

    const dataSourceMap = {
        1: "google meu negócio",
        2: "instagram",
        3: "reclame aqui"
    };

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                setCurrPage(prevPage => prevPage + 1);
            }
        }
    };

    const fetchData = async (currPage, setLoading, dataListSetter, totalSetter, isInitial = false) => {
        let URI = `https://api-vinbolai-dev.azurewebsites.net/comments/?cnpj=${targetCnpj}${filterPostUrl ? `&postUrl=${filterPostUrl}` : ""}${filterDateRange?.startDate ? `&startDate=${filterDateRange.startDate}` : ""}&endDate=${dataPoint['date']}&page=${currPage}&size=20`;
        
        if (isInitial) {
            setInitialLoading(true);
        } else {
            setLoading(true);
        }

        const response = await axios.get(URI);
        console.log(`Fetched page ${currPage} for comments`, response.data.items);

        dataListSetter((prevData) => isInitial ? response.data.items : [...prevData, ...response.data.items]);
        totalSetter(response.data.total);

        if (isInitial) {
            setInitialLoading(false);
        } else {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (!loading && currPage > 1) {
            fetchData(currPage, setLoading, setCommentsData, setTotalComments);
        }
    }, [currPage]);

    React.useEffect(() => {
        const fetchInitialData = async () => {
            await fetchData(1, setLoading, setCommentsData, setTotalComments, true);
        };

        fetchInitialData();
    }, [targetCnpj, filterDateRange, filterPostUrl, dataPoint]);

    const handleSelectComment = (commentId) => {
        setSelectedComments((prevSelected) => {
            const newSelected = prevSelected.includes(commentId) 
                ? prevSelected.filter(id => id !== commentId)
                : [...prevSelected, commentId];
            
            console.log('Selected comments:', newSelected);
            return newSelected;
        });
    };

    const handleSelectAll = (isChecked) => {
        if (isChecked) {
            const allCommentIds = commentsData.map(comment => comment.id);
            setSelectedComments(allCommentIds);
        } else {
            setSelectedComments([]);
        }
    };

    const handleReclassifyComments = async () => {
        const reclassifyEndpoint = 'https://api-vinbolai-dev.azurewebsites.net/reclassify_comments';
        const compoundValue = newClassification === 'pos' ? '0.3' : newClassification === 'neg' ? '-0.3' : '0.0';
        const payload = {
            comments: selectedComments.map(id => ({
                id_comment: id,
                compound: compoundValue,
                sent_final: newClassification
            }))
        };

        try {
            console.log(`Reclassifying comments with payload:`, payload);
            const response = await axios.post(reclassifyEndpoint, payload);
            if (response.status === 200) {
                setSelectedComments([]);
                setDialogOpen(false);
                await fetchData(1, setLoading, setCommentsData, setTotalComments, true);
            } else {
                console.error('Reclassification failed:', response);
            }
        } catch (error) {
            console.error('Error reclassifying comments:', error);
        }
    };

    const handleBulkActionClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBulkActionClose = () => {
        setAnchorEl(null);
    };

    const handleBulkActionSelect = (action) => {
        if (action === 'reclassify' && selectedComments.length > 0) {
            setDialogOpen(true);
        }
        handleBulkActionClose();
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Typography variant="subtitle2" color="textSecondary">Ações em massa</Typography>
                    <Button
                        aria-controls="bulk-action-menu"
                        aria-haspopup="true"
                        onClick={handleBulkActionClick}
                        style={{ fontSize: '12px' }}
                    >
                        Selecione Ação
                    </Button>
                    <Menu
                        id="bulk-action-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleBulkActionClose}
                    >
                        <MenuItem onClick={() => handleBulkActionSelect('reclassify')}>Reclassificar Selecionados</MenuItem>
                    </Menu>
                </Box>
                <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
            </Grid>

            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Checkbox
                        indeterminate={selectedComments.length > 0 && selectedComments.length < commentsData.length}
                        checked={selectedComments.length === commentsData.length}
                        onChange={(e) => handleSelectAll(e.target.checked)}
                    />
                    <Typography variant="body2">Selecionar todos</Typography>
                </Box>
                {initialLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                        <CircularProgress />
                    </Box>
                ) : (
                    <div onScroll={onScroll} ref={listInnerRef} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                        {commentsData
                            .filter(item => matchTags(dataSourceMap[item.id_data_sources_fk], currentTags) && matchWords(item.comentario, filterWords))
                            .map((item, index) => (
                                <Box key={index} display="flex" alignItems="center" mb={2}>
                                    <Checkbox
                                        checked={selectedComments.includes(item.id)}
                                        onChange={() => handleSelectComment(item.id)}
                                    />
                                    <TweetBox
                                        currentTags={currentTags}
                                        setCurrentTags={setCurrentTags}
                                        classification={item.classification}
                                        tags={[
                                            ...(item.tags || []),
                                            dataSourceMap[item.id_data_sources_fk],
                                            item.classification === 'pos' ? 'positivo' : item.classification === 'neg' ? 'negativo' : 'neutro'
                                        ]}
                                        {...{
                                            title: item.nome,
                                            picture: item.imagem,
                                            date: transformDate(item.data_aprox),
                                            message: item.comentario,
                                            origin: item.id_data_sources_fk,
                                            ...(item.data_aprox === dataPoint['date']) && { newMention: true }
                                        }}
                                    />
                                </Box>
                            ))}
                        {loading && (
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                <CircularProgress size={24} />
                            </Box>
                        )}
                    </div>
                )}
            </Grid>

            {/* Reclassification Dialog */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Reclassificar Comentários</DialogTitle>
                <DialogContent>
                    <Box display="flex" alignItems="center" mb={2} p={2} style={{ backgroundColor: '#ffb3004d', borderRadius: '4px' }}>
                        <ReportGmailerrorredOutlinedIcon style={{ color: '#ffb300', marginRight: '8px' }} />
                        <DialogContentText>
                            Todos os comentários selecionados serão reclassificados para a opção escolhida abaixo. Tenha em mente que essa modificação irá alterar o ACPS não só da campanha, mas também da marca.
                        </DialogContentText>
                    </Box>
                    <RadioGroup
                        aria-label="new-classification"
                        name="new-classification"
                        value={newClassification}
                        onChange={(e) => setNewClassification(e.target.value)}
                    >
                        <FormControlLabel value="pos" control={<Radio />} label="Positivo" />
                        <FormControlLabel value="neg" control={<Radio />} label="Negativo" />
                        <FormControlLabel value="neu" control={<Radio />} label="Neutro" />
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Cancelar</Button>
                    <Button onClick={handleReclassifyComments} color="primary" variant="contained">
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
