import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoIcon from "../../../assets/svg/Logo";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import styled from 'styled-components';
import GoogleIcon from '@mui/icons-material/Google';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Todos os direitos reservados ® '}
      <Link color="inherit" href="/">
        Vinbol
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const GoogleButton = styled(Button)({
  backgroundColor: 'white',
  color: 'black',
  textTransform: 'none',
  border: '1px solid #ccc',
  padding: '10px 20px',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    boxShadow: 'none',
  },
});

export default function SignIn() {
  const [showDialog, setShowDialog] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [resetEmail, setResetEmail] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passError, setPassError] = React.useState(false);
  const [showEmailForm, setShowEmailForm] = React.useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const handleGoogleSignup = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // Handle successful Google sign-in, e.g., navigate to dashboard
      navigate("/dashboard");
    } catch (error) {
      console.error('Error during Google Sign-In', error);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    if (email.trim() === '') {
      setEmailError(true);
      return;
    } else if (password.trim() === '') {
      setPassError(true);
      return;
    } else if (resetEmail) {
      triggerResetEmail();
      return;
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          // console.log(userCredential.user.uid)
          navigate("/dashboard");
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === 'auth/invalid-login-credentials') {
            alert("Credenciais inválidas, por favor, revise o endereço de e-mail e senha digitados.");
          }
        });
    }
  };

  const triggerResetEmail = async () => {
    await sendPasswordResetEmail(auth, email);
    alert("E-mail de redefinição de senha enviado");
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <LogoIcon />
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Entrar na Vinbol.AI
          </Typography>
          {!showEmailForm ? (
            <React.Fragment>
              <GoogleButton
                variant="contained"
                startIcon={<GoogleIcon />}
                fullWidth
                onClick={handleGoogleSignup}
                sx={{ mt: 3, mb: 2 }}
              >
                Continuar com Google
              </GoogleButton>
              <Divider sx={{ width: '100%', mb: 2 }}>OU</Divider>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setShowEmailForm(true)}
                sx={{ mb: 2 }}
              >
                Continuar com Email
              </Button>
            </React.Fragment>
          ) : (
            <Box component="form" onSubmit={onLogin} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Endereço de email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(e.target.value.trim() === ''); // Checa se não está vazio
                }}
                error={emailError}
                helperText={emailError ? 'Campo obrigatório' : ''}
              />
              {!resetEmail ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPassError(e.target.value.trim() === ''); // Checa se não está vazio
                  }}
                  error={passError}
                  helperText={passError ? 'Campo obrigatório' : ''}
                />
              ) : null}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!resetEmail ? "Entrar" : "Enviar instruções para o e-mail"}
              </Button>
              <Grid container>
                <Grid item xs>
                  {!resetEmail ? (
                    <Link href="#" onClick={() => setResetEmail(true)} variant="body2">
                      Esqueceu a senha?
                    </Link>
                  ) : (
                    <Link href="#" onClick={() => setResetEmail(false)} variant="body2" component="button">
                      Voltar para o login
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DialogBox = styled.div`
  background: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;
