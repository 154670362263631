import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Title from './Title';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Modal from '@mui/material/Modal';
import zonas_acps from "../../../assets/img/zonas_acps.png";

const RADIAN = Math.PI / 180;

const lightThemeData = [
  { name: 'Zona Crítica', value: 50, color: '#455577' },
  { name: 'Zona de Aperfeiçoamento', value: 25, color: '#6880A2' },
  { name: 'Zona de Qualidade', value: 12, color: '#99B8D5' },
  { name: 'Zona de Excelência', value: 13, color: '#87c228' }
];

const darkThemeData = [
  { name: 'Zona Crítica', value: 50, color: '#374151' },
  { name: 'Zona de Aperfeiçoamento', value: 25, color: '#4B5563' },
  { name: 'Zona de Qualidade', value: 12, color: '#6B7280' },
  { name: 'Zona de Excelência', value: 13, color: '#10B981' }
];

const SentimentEmojis = [
  '😢', // Zona Crítica
  '😐', // Zona de Aperfeiçoamento
  '😊', // Zona de Qualidade
  '😄'  // Zona de Excelência
];

const CustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index, fill, darkMode }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  const emoji = SentimentEmojis[index];
  const emojiFill = darkMode ? '#ffffff' : `${fill}80`;

  return (
    <text x={x} y={y} fill={emojiFill} fontSize={20} textAnchor="middle" dominantBaseline="central">
      {emoji}
    </text>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '42%',
  left: '65%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: "1000",
  overflowY: "auto"
};

export default function Deposits({ chartData, date, darkMode }) {
  const [chartVal, setChartVal] = React.useState(0);
  const cx = 110;
  const cy = 100;
  const iR = 50;
  const oR = 100;
  const value = chartVal;

  const data = darkMode ? darkThemeData : lightThemeData;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    setChartVal(chartData);
  }, [chartData]);

  const needle = (value, data, cx, cy, iR, oR, color) => {
    const ang = 180.0 * (1 - (value + 100) / 200);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="none" fill={color} />,
    ];
  };

  return (
    <React.Fragment>
      <Grid container>
        <Title>ACPS em <Typography component="h2" sx={{ textDecoration: 'underline' }} display="inline" variant="h6" color="primary" gutterBottom>{date}</Typography></Title>
        <IconButton onClick={handleOpen} style={{ marginLeft: '1px', marginTop: '-8px' }}>
          <HelpCenterOutlinedIcon />
        </IconButton>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h5" id="modal-modal-title" align="center" style={{ margin: 5 }}>
            ACPS Diário
          </Typography>
          <Grid container>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Esse gráfico mostra o valor do ACPS da marca no dia, o classificando em quatro zonas conforme a imagem abaixo.
            </Typography>
            <img src={zonas_acps} width={640} alt="" />
          </Grid>
        </Box>
      </Modal>
      <ResponsiveContainer minWidth={215}>
        <PieChart style={{ marginLeft: "8px" }} width={400} height={500}>
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            labelLine={false}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
            label={(props) => <CustomLabel {...props} darkMode={darkMode} />}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, darkMode ? '#fff' : '#d0a300')}
        </PieChart>
      </ResponsiveContainer>
      <Paper
        style={{
          backgroundColor: darkMode ? '#1e1e1e' : 'rgba(169, 169, 169, 0.2)',
          padding: '8px',
          display: 'inline-block',
          marginTop: "-80px",
          height: "50px"
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%', // Ensures full height for proper centering
          }}
        >
          <Typography 
            align="center" 
            variant="h4" 
            style={{ 
              fontFamily: "Roboto", 
              color: darkMode ? '#00a651' : '#000000', 
              fontSize: '3rem' // Aumenta a fonte para preencher o espaço
            }}
          >
            {Math.trunc(value)?.toLocaleString()} {/* Exibe apenas a parte inteira sem arredondar */}
          </Typography>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
